define("discourse/plugins/discourse-category-experts/discourse/connectors/above-review-filters/endorsed-username-to-filters", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component"], function (_exports, _component, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EndorsedUsernameToFilters = dt7948.c(class EndorsedUsernameToFilters extends _component.default {
    updateEndorsedUsername(usernames) {
      (0, _object.set)(this, "outletArgs.additionalFilters.endorsed_username", usernames[0]);
    }
    static #_ = (() => dt7948.n(this.prototype, "updateEndorsedUsername", [_object.action]))();
  }, [(0, _component2.classNames)("endorsed-username-to-filters")]);
  var _default = _exports.default = EndorsedUsernameToFilters;
});