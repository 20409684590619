define("discourse/plugins/discourse-category-experts/discourse/templates/connectors/above-review-filters/endorsed-username-to-filters", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="reviewable-filter reviewable-filter-endorsed-username-to-filter">
    <label class="filter-label">{{i18n "review.endorsed_username"}}</label>
    <EmailGroupUserChooser
      @value={{this.outletArgs.additionalFilters.endorsed_username}}
      @onChange={{this.updateEndorsedUsername}}
      @autocomplete="off"
      @options={{hash fullWidthWrap=true maximum=1}}
    />
  </div>
  */
  {
    "id": "j4yhatlH",
    "block": "[[[10,0],[14,0,\"reviewable-filter reviewable-filter-endorsed-username-to-filter\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"filter-label\"],[12],[1,[28,[35,2],[\"review.endorsed_username\"],null]],[13],[1,\"\\n  \"],[8,[39,3],null,[[\"@value\",\"@onChange\",\"@autocomplete\",\"@options\"],[[30,0,[\"outletArgs\",\"additionalFilters\",\"endorsed_username\"]],[30,0,[\"updateEndorsedUsername\"]],\"off\",[28,[37,4],null,[[\"fullWidthWrap\",\"maximum\"],[true,1]]]]],null],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"email-group-user-chooser\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/templates/connectors/above-review-filters/endorsed-username-to-filters.hbs",
    "isStrictMode": false
  });
});